<template>
  <div class="fill_new_zhiyuan" v-if="visible">
    <div class="form_content">
      <h2>
        <span v-if="currentYear">{{ currentYear }} 志愿模拟填报</span>
        <span class="city_name">{{ currentProvince }}</span>
      </h2>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <div>
          <el-form-item label="模拟总分" label-width="100px" prop="totalScore">
            <el-input
              class="score_num"
              v-model.number="ruleForm.totalScore"
              placeholder="模拟总分"
            ></el-input>
            <span>分</span>
          </el-form-item>
        </div>

        <el-form-item label="位次/排名" label-width="100px" prop="position">
          <el-input
            class="score_num"
            v-model.number="ruleForm.position"
            placeholder="位次/排名"
          ></el-input>
          <span>分</span>
        </el-form-item>

        <el-form-item
          class="course_checkbox_option"
          label-width="100px"
          prop="checkCourseList"
        >
          <el-checkbox-group
            v-model="ruleForm.checkCourseList"
            :max="3"
            :min="1"
          >
            <el-checkbox
              border
              v-for="(item, index) in courseList"
              :key="index"
              :label="item.name"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item class="btn_wrap">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    var checkYuguNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("模拟总分不能为空"));
      } else if (value < 100 || value > 750) {
        return callback(new Error("总分应为 100-750"));
      } else {
        callback();
      }
    };
    var checkXianchaNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("位次/排名不能为空"));
      } else {
        callback();
      }
    };
    return {
      labelWidth: "100px",
      courseList: [],
      ruleForm: {
        type: 0,
        totalScore: null,
        position: null,
        score: null,
        checkCourseList: []
      },
      rules: {
        totalScore: [
          { validator: checkYuguNumber, trigger: "blur" },
          { type: "number", message: "请输入数字" }
        ],
        position: [
          { validator: checkXianchaNumber, trigger: "blur" },
          { type: "number", message: "请输入数字" }
        ],
        checkCourseList: [
          {
            required: true,
            message: "请选择3个学科",
            trigger: "change"
          }
        ]
      },
      scoreOptions: [],
      localArea: {}
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      isNew: state => state.isNew
    })
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    }
  },
  
  mounted() {
    if (this.userInfo) {
      this.getNewKeMu(); //获取科目
    }
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let newCourse = [];
          if (this.ruleForm.checkCourseList.length == 3) {
            this.ruleForm.checkCourseList.forEach(item => {
              newCourse.push(item.substr(0, 1));
            });
          } else {
            this.$toast("请选择3个学科");
            return;
          }
          let apiUrl = "";
          let params = {
            point: this.ruleForm.totalScore, //总分
            type: "",
            tag: "",
            position: this.ruleForm.position, //位次
            subjects: newCourse, //科目
            year: this.currentYear
          };

          if (this.type == "create") {
            apiUrl = "/api/school/query/";
            params.province = this.userInfo.province;
          } else if (this.type == "update") {
            apiUrl = "/api/user/score/";
          }

          console.log("参数---", params);
          try {
            const res = await this.$axios.post(apiUrl, params);
            if (res.data.errno === 0) {
              const Data = res.data.results;
              if (this.type == "create") {
                localStorage.setItem("scoreInfoData", JSON.stringify(params));
                // this.$store.dispatch("getUserInfo"); //获取个人信息
                
              } else {
                // this.$emit("update:visible", false);
                // this.$store.dispatch("getUserInfo");
              }
              this.$router.push({ path: "/" });
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    // 获取新高考科目
    async getNewKeMu() {
      const res = await this.$axios.get(
        `/api/province/subject/?province_name=${this.userInfo.province}`
      );
      this.courseList = res.data.results;
    }
  }
};
</script>

<style lang="scss" scoped>
.fill_new_zhiyuan {
  width: 100%;
  // height: 420px;

  z-index: 100;
  background: #fff;
  .form_content {
    width: 100%;
    // height: 420px;
    h2 {
      font-size: 0.32rem;
      padding-top: 0.5rem;
      padding-left: 20px;
      margin-bottom: 20px;
      color: #444;
      display: flex;
      justify-content: space-between;
      padding-right: 36px;
      align-items: center;
      .city_name {
        font-size: 16px;
        color: #910000;
        font-weight: 600;
      }
    }
    .course_checkbox_option {
      margin-top: 0.5rem;
    }
    .btn_wrap {
      padding: 0 0.5rem;
    }

    .el-checkbox {
      margin-right: 0.1rem;
      margin-bottom: 0.25rem;
      .el-checkbox__input {
        display: none;
      }
    }
    .el-checkbox.is-bordered {
      height: 0.76rem;
    }
    .el-checkbox-group {
      .el-checkbox:nth-child(4n) {
        margin-left: 0;
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #910000;
    }
    .el-checkbox.is-bordered.is-checked {
      border-color: #910000;
    }

    .el-form-item__content {
      display: flex;
      .el-input {
        margin-right: 10px;
        &.score_num {
          width: 80%;
        }
      }

      .el-button--primary {
        width: 100%;
        margin-top: 20px;
        font-size: 0.3rem;
      }
    }
  }
}
</style>
