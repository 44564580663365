<template>
  <div class="fill_score_page">
    <fill-zhi-yuan
      v-if="userInfo && !userInfo.is_new"
      :visible.sync="formVisible"
      :type="type"
    ></fill-zhi-yuan>

    <new-fill-zhi-yuan
      v-if="userInfo && userInfo.is_new"
      :visible.sync="formVisible"
      :type="type"
    ></new-fill-zhi-yuan>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FillZhiYuan from "@/components/FillZhiYuan";
import newFillZhiYuan from "@/components/FillZhiYuan/newBoard";

export default {
  components: {
    FillZhiYuan,
    newFillZhiYuan
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      isNew: state => state.isNew
    })
  },
  data() {
    return {
      cityValue: "",
      formVisible: true,
      cityPopupShow: false,
      areaOptions: [],
      type: "create"
    };
  },
  mounted() {
    this.type = this.$route.params.type;
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.fill_score_page {
  height: 100vh;
  background: #fff;
}
</style>
