<template>
  <div class="fill_zhiyuan" v-if="visible">
    <div class="form_content">
      <h2>
        <span v-if="currentYear"
          >{{ currentYear }} 志愿模拟填报</span
        >
      </h2>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="科类" :label-width="labelWidth" prop="type">
          <el-radio-group
            v-if="!radioGroupDisable"
            @change="changeType"
            v-model="ruleForm.type"
          >
            <el-radio-button :label="0">文科</el-radio-button>
            <el-radio-button :label="1">理科</el-radio-button>
          </el-radio-group>
          <el-radio-group v-else v-model="ruleForm.type">
            <el-radio-button disabled :label="0">文科</el-radio-button>
            <el-radio-button disabled :label="1">理科</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="成绩 " :label-width="labelWidth">
          <el-tabs v-model="tabActiveName" @tab-click="handleClickTab">
            <el-tab-pane label="预估分数" name="0"></el-tab-pane>
            <el-tab-pane label="线差" name="1"></el-tab-pane>
          </el-tabs>
        </el-form-item> -->
        <div v-if="tabActiveName == 0">
          <el-form-item label="总分" :label-width="labelWidth" prop="yuguScore">
            <el-input
              style="width: 80%;"
              class="score_num"
              v-model.number="ruleForm.yuguScore"
              placeholder="高考分数"
            ></el-input>
            <span>分</span>
          </el-form-item>
        </div>
        <div class="xiancha_wrap" v-else>
          <el-select
            v-model="ruleForm.scoreType"
            @change="calcScore()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in scoreOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-form-item label="" label-width="10px" prop="xianchaScore">
            <el-input
              v-model.number="ruleForm.xianchaScore"
              placeholder="线差分数"
              @change="calcScore()"
            ></el-input>
            <span>分</span>
          </el-form-item>
        </div>
        <el-form-item
          label="位次"
          v-if="type != 'create'"
          :label-width="labelWidth"
        >
          <el-input
            style="width: 80%"
            v-model.number="ruleForm.position"
            placeholder="本省排名"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="0px" class="confirm_btn_box">
          <el-button
            type="primary"
            class="confirm_btn"
            @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    var checkYuguNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("分数不能为空"));
      } else if (value < 100 || value > 750) {
        return callback(new Error("总分应为 100-750"));
      } else {
        callback();
      }
    };
    var checkXianchaNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("分数不能为空"));
      } else if (value < 0 || value > 150) {
        return callback(new Error("线差应在 0-150"));
      } else {
        callback();
      }
    };
    return {
      labelWidth: "70px",
      ruleForm: {
        type: 0,
        yuguScore: null,
        xianchaScore: null,
        score: null,
        scoreType: "",
        position: null
      },
      radioGroupDisable: false,
      rules: {
        type: [
          {
            required: true,
            message: "请选择科目",
            trigger: "change"
          }
        ],
        yuguScore: [
          { validator: checkYuguNumber, trigger: "blur" },
          { required: true, type: "number", message: "请输入数字" }
        ],
        xianchaScore: [
          { validator: checkXianchaNumber, trigger: "blur" },
          { type: "number", message: "请输入数字" }
        ]
      },
      tabActiveName: 0,
      scoreOptions: [],
      localArea: {}
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      isNew: state => state.isNew,
      currentYear: state => state.currentYear
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "create"
    }
  },
  async mounted() {
    if (this.userInfo && this.userInfo.type) {
      this.userInfo.type == "文科"
        ? (this.ruleForm.type = 0)
        : (this.ruleForm.type = 1);
    }
    this.changeType(this.ruleForm.type);
    if (this.userInfo && this.userInfo.point) {
      this.radioGroupDisable = true;
    }

    if (this.userInfo && this.userInfo.province && !this.userInfo.is_new) {
      await this.getPiCi(); //获取批次
    }
  },
  methods: {
    changeType(value) {
      localStorage.setItem("storeCurrentType", value == 0 ? "文科" : "理科");
    },
    //计算线差分数
    calcScore() {
      this.scoreOptions.forEach(item => {
        if (item.id == this.ruleForm.scoreType) {
          this.ruleForm.score = item.point + this.ruleForm.xianchaScore;
        }
      });
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.type == "create") {
            this.createScore();
          } else if (this.type == "update") {
            this.changeScore();
          }
        }
      });
    },
    async createScore() {
      const apiUrl = "/api/province/data/query/";
      let params = {
        province:
          this.userInfo && this.userInfo.province
            ? this.userInfo.province
            : localStorage.getItem("localProvince"),
        point:
          this.tabActiveName == 0
            ? this.ruleForm.yuguScore
            : this.ruleForm.score,
        type: this.ruleForm.type == 0 ? "文科" : "理科",
        gap_point: this.ruleForm.xianchaScore,
      };

      // console.log("66666", params);

      try {
        const res = await this.$axios.post(apiUrl, params);
        if (res.data.errno === 0) {
          const Data = res.data.results;
          localStorage.setItem("scoreInfoData", JSON.stringify(params));
         
          this.$router.push({ path: "/" });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async changeScore() {
      const apiUrl = "/api/user/score/";
      let params = {
        point:
          this.tabActiveName == 0
            ? this.ruleForm.yuguScore
            : this.ruleForm.score,
        type: this.ruleForm.type == 0 ? "文科" : "理科",
        position: this.ruleForm.position,
        gap_point: this.ruleForm.xianchaScore,
      };

      try {
        const res = await this.$axios.post(apiUrl, params);
        if (res.data.errno === 0) {
          const Data = res.data.results;
          this.$store.dispatch("getUserInfo");
          setTimeout(() => {
            this.$emit("update:visible", false);
            this.$router.push({ path: "/" });
          }, 500)
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPiCi() {
      const type = this.ruleForm.type == 0 ? "文科" : "理科";
      const res = await this.$axios.get(
        `/api/tag/?province__name=${this.userInfo.province}&type=${type}&year=${this.currentYear}`
      );
      this.scoreOptions = res.data.results.results;
      this.ruleForm.scoreType = this.scoreOptions[0].id;
    },
    handleClickTab() {
      this.ruleForm.score = "";
      this.ruleForm.xianchaScore = "";
      this.ruleForm.yuguScore = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.fill_zhiyuan {
  width: 100%;
  // height: 100vh;
  z-index: 100;
  background: #fff;
  .form_content {
    width: 100%;
    // height: 100vh;
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #910000;
      border: 1px solid #910000;
      box-shadow: none;
    }
    .el-radio-button.is-active .el-radio-button__orig-radio:disabled+.el-radio-button__inner{
      color: #fff;
    }
    h2 {
      font-size: 0.32rem;
      padding-left: 20px;
      padding-top: 0.5rem;
      margin-bottom: 20px;
      color: #444;
      display: flex;
      justify-content: space-between;
      padding-right: 36px;
      align-items: center;
      .city_name {
        font-size: 16px;
        color: #910000;
        font-weight: 600;
      }
    }
    .el-tabs {
      width: 100%;
      padding-right: 40px;
    }

    .xiancha_wrap {
      display: flex;
      padding: 0 20px 0 70px;
      .el-select {
        width: 40%;
        .el-input {
          width: 100%;
          margin-right: 10px;
        }
      }
    }

    .confirm_btn_box {
      width: 100%;
      bottom: 2rem;
    }
    .el-form-item__content {
      display: flex;
      .el-input {
        margin-right: 10px;
        &.score_num {
          width: 80%;
        }
      }
      .el-tabs__header {
        margin: 0;
      }

      /deep/ .el-tabs__active-bar {
        background: #910000;
      }

      .confirm_btn.el-button--primary {
        width: 86%;
        margin: 0 auto;
        margin-top: 40px;
        font-size: 16px;
      }
    }
  }
}
</style>
